function isIOS() {
    var ua = window.navigator.userAgent;
    return /(iPad|iPhone|iPod).*WebKit/.test(ua) && !/(CriOS|OPiOS)/.test(ua);
}
var showBoxIcon = function() {
    var cnt = 0;
    var data = JSON.parse(window.localStorage.getItem('setBoxIcon'));
    if(!data || isExpired(data)) {
        var timestamp = new Date().valueOf();
        var itemData  = JSON.stringify({
            timestamp: timestamp,
            content: 1
        });
        cnt = 1;
        window.localStorage.setItem( 'setBoxIcon', itemData );
    }
    else {
        cnt = data.content;
        var itemData  = JSON.stringify({
            timestamp: data.timestamp,
            content: parseInt(1 + data.content)
        });
        window.localStorage.setItem( 'setBoxIcon', itemData );
    }
    return cnt <= 1;
};
var expire = 1000 * 60 * 60 * 0.5;
var isExpired = function( data ){
    var presentTime = new Date().valueOf();
    if ( ! data || ! data.timestamp ) {
        return true;
    }
    return presentTime - data.timestamp > expire;

};
var widthClient = $(window).width();
var msgIcon = $("div.msgSetIcon");
if(isIOS() && window.location.pathname == "/" && showBoxIcon()) {
    msgIcon.css('opacity',1).fadeIn();
    setTimeout(function(){msgIcon.fadeOut();},5000);
}
if(widthClient < 768) {
    $("a.submenu i").removeClass('fa-angle-down').addClass('fa-angle-right');
}
var editions = $(".box-list-editions a.edition, .box--Editions span.edition");
editions.click(function(event) {
    event.preventDefault();
    $('html,body').css('opacity', '0.75');
    var edition = $(this).attr('data-section');
    document.cookie = "editionUser=" + edition;
    document.location = '/';
});
var smartMenu = true;//(widthClient < 991);
var nolinks = $('a.preventDefault');
nolinks.each(function() {
    $(this).on('click', function(e) {
        e.preventDefault();
    });
});
$("a.makeComplaintLink").click(function() {
    var content = $("#contentFrameComplaint");
    var frame = document.createElement('iframe');
    frame.src = "/get-section-complaint";
    content.html('');
    $(frame).appendTo(content);
});

/*var adMax = document.createElement('script');
adMax.src = "/js/adMax.js";
var fsa = document.getElementsByTagName('script')[0];
fsa.parentNode.insertBefore(adMax, fsa);*/

var strongRegex = new RegExp("^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\W).*$", "g");
var mediumRegex = new RegExp("^(?=.{7,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*$", "g");
var enoughRegex = new RegExp("(?=.{6,}).*", "g");
var passwordInput = $("#passwordUserNew"), boxResults = $("#boxResultsPassword");
passwordInput.keyup(function(e) {
    if(e.keyCode >= 65 || e.keyCode == 8) {
        boxResults.find('span').html('');
        var text = $(this).val().trim();
        var dots = boxResults.find('ul li');
        if(text.length > 0) {
            for(var i=0;i<4;i++)
                $(dots[i]).css({background:"#fff"});
            if(strongRegex.test(text)||text.length>20) {
                for(var i=0;i<4;i++)
                    $(dots[i]).css({background:"#9c6"});
                boxResults.find('span').html('Fuerte');
            }
            else if(mediumRegex.test(text)||text.length>15) {
                for(var i=0;i<3;i++)
                    $(dots[i]).css({background:"#ffa500"});
                boxResults.find('span').html('Segura');
            }
            else if(enoughRegex.test(text)||text.length>10) {
                $(dots[0]).css({background:"#cd5c5c"});
                $(dots[1]).css({background:"#cd5c5c"});
                boxResults.find('span').html('Suficiente');
            }
            else {
                $(dots[0]).css({background:"#ED1C24"});
                boxResults.find('span').html('Insegura');
            }
        }
    }
});
$("a.makeSocialLogin").click(function(e) {
    e.preventDefault();
    window.location = $(this).attr('data-href');

});
$("span.close-panel").click(function() {
    var parent = $(this).parent().parent().parent();
    parent.toggle();
    parent.prev().find('i').removeClass('fa-caret-up').addClass('fa-caret-down');
});
$("a.open-modal").click(function(e) {
    e.preventDefault();
    if($(this).find('i').hasClass('fa-caret-down'))
        $(this).find('i').removeClass('fa-caret-down').addClass('fa-caret-up');
    else
        $(this).find('i').removeClass('fa-caret-up').addClass('fa-caret-down');
    $(this).next().toggle()
});
$("#btnForgotten").click(function() {
    var btn = $(this);
    var boxEmail = $("#emailForgotten");
    var tEmail = boxEmail.val().trim();
    if(tEmail.length > 0) {
        rAjax('/recuperar-contrasenia',{email:tEmail},function(data){

        },function(error){

        },function(){
            btn.append('<i class="fa fa-cog fa-spin"><i>');
        })
    }
    else {
        boxEmail.css({border: '1px solid red'});
    }
});
$("a.linkChange").click(function(event) {
    event.preventDefault();
    $(this).parent().parent().fadeOut();
    var act = $(this).attr('href');
    var data = $(this).attr('data');
    setTimeout(function(){$(act).fadeIn();$("span#titleModal").html(data);}, 400);
});
var btnSt = $("div.ScrollTopPage");
var $navbar = $("#navbar"),
    y_pos = $navbar.offset().top,
    height = $navbar.height(), y_posOld = 0, sMS = true, cnt=0;
$(document).scroll(function() {
    cnt++;
    if(smartMenu) {
        var scrollTop = $(this).scrollTop();
        if (scrollTop > y_pos + height) {
            $navbar.addClass("menu-fixed").animate({
                top: 0
            });
        } else if (scrollTop <= y_pos) {
            $navbar.removeClass("menu-fixed").clearQueue().animate({
                top: "-58px"
            }, 0);
        }
        if(scrollTop > 300)
            btnSt.addClass('py');
        else
            btnSt.removeClass('py');
    }
    else if(sMS) {

    }
});
btnSt.click(function(){$("html,body").animate({scrollTop: 0}, 800)});
var hamburguer = $('span#Hamburguer'),
    themenu = $('ul.Main--Nav.ALL'),
    themenuelement = $('ul.Main--Nav.ALL li:not(#closeMenu)'),
    navbar = $('nav#navbar'),
    closeMenu = $("#closeMenu"),
    closeUser = $("#closeMenuUser");
var userImg = $("a.userImgLog"),
    menuUser = $('ul.Main--Nav.USER'),
    elementUser = $('ul.Main--Nav.USER li');
if(widthClient < 768) {
    themenu.on('click', function(e){
        if(e.offsetX > e.currentTarget.offsetWidth) {
            $("html, body").removeClass('no-scroll');
            themenu.removeClass('show-menu');
        }
    });
    menuUser.on('click', function(e){
        if(e.offsetX < 0) {
            $("html, body").removeClass('no-scroll');
            menuUser.removeClass('show-menu-user');
        }
    });
}
hamburguer.on('click', function() {
    $("html, body").addClass('no-scroll');
    menuUser.hide();
    themenu.addClass('show-menu');
});
closeMenu.on('click', function() {
    $("html, body").removeClass('no-scroll');
    themenu.removeClass('show-menu');
});
closeUser.on('click', function() {
    $("html, body").removeClass('no-scroll');
    menuUser.removeClass('show-menu-user');
});
themenuelement.on('click', function() {
    if(widthClient < 991) {
        //themenu.slideToggle();
        sMS = !sMS;
    }
});
userImg.on('click', function (event) {
    event.preventDefault();
    themenu.hide();
    menuUser.addClass('show-menu-user');
    $("html, body").addClass('no-scroll');
});
elementUser.on('click', function() {menuUser.slideToggle();});
if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
    document.body.className += " safari";
}
var x = 50;
$("a.Page_Scroll, .list-inline.Main--Nav a[href=#]").click(function(a) {
    if($(this).hasClass('submenu'))
        return false;
    a.preventDefault();
    var b = $(this).attr('class');
    var c = b.split(' ')[1].split('__')[0].split('-')[1];
    if(widthClient < 768) {
        $("html, body").removeClass('no-scroll');
        themenu.removeClass('show-menu').css('display', 'none');
    }
    if(window.location.pathname == "/")
        makeScroll(c);
    else
        window.location = "/#" + c;
});
if(window.location.pathname == "/" && window.location.hash.length > 2)
    setTimeout(function(){
        x = 100;
        var d = window.location.hash.toString().split('#')[1];
        makeScroll([d]);
    },800);
$("span.Close__Cookie").click(function(){setCookie('acceptPrivacy','true',1);});
checkCookie();
function makeSectionVideos() {
    console.log('Fui llamado despues de cargar!');
}
function makeScroll(a) {
    var ref = 110;
    if($("#navbar").hasClass('menu-fixed'))
        ref = 65;
    $("h4.u__" + a).parent()[0].scrollIntoView({block: "start", behavior: "smooth"});
    /*setTimeout(function(){
        $("html, body").animate({scrollTop:window.scrollY - ref},300);
    },1000);*/
}
function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    checkCookie();
}
function checkCookie() {
    var a = $("div.Cookie__Footer");
    var user = getCookie("acceptPrivacy");
    if (user == "") {
        a.css('display', 'block');
    }
    else {
        a.css('display', 'none');
    }
}
function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
$.fn.live = function( events, handler ) {};
setTimeout(function(){window.location.reload()},600000);
function rAjax(url,data,success,error,before){$.ajaxSetup({headers:{'X-CSRF-TOKEN':window.Laravel.csrfToken }});$.ajax({type:"POST",url:url,data:data,beforeSend:before,success:success,error:error});}